// @flow

/**
  Loads helpers onto the global so that they can be referenced by legacy code
  This should be used sparingly so we can not load extra javascript the client
  doesn't need.

  To add a new helper, import it and put it in below the others.
 */
import { extend } from "lodash"

import * as Color from "helpers/color"
import * as Grapher from "helpers/grapher"
import * as Navigation from "helpers/navigation"
import * as String from "helpers/string"
import * as Time from "helpers/time"
import * as TimeFormatter from "helpers/time_formatter"
import Currency from "helpers/currency"
import * as NumberHelpers from "helpers/number_helpers"
import * as Function from "helpers/function"
import * as Spin from "helpers/spinner"
import Flash from "helpers/flash"
import validateABN from "helpers/validate_abn"
import * as Airbrake from "../airbrake"

window.LH = extend({}, window.LH, {
  /* insert globally exposed deps here */
  Currency,
  NumberHelpers,
  Color,
  Grapher,
  Navigation,
  String,
  Time,
  TimeFormatter,
  Airbrake,
  Function,
  Spin,
  Flash,
  validateABN,
})
